import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from '../../Components/Auth/AuthContext'; 
import euro from '../../Assets/euroBlue.webp';
import axios from 'axios';

const Users = () => {
  const { currentUser, balance, email, uuid,  fwinBackendUrl, fetchUserBalance} = useAuth();
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [twoFaModel, setTwoFaModel] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState();
  const [selectedUser, setSelectedUser] = useState(null);
  const [otp, setOtp] = useState(Array(6).fill(''));
  const inputRefs = useRef([]);
  const [activeTab, setActiveTab] = useState('all');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${fwinBackendUrl}/admin/users`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setUserData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);
  const handleEditClick = (user, index) => {
    setSelectedUser(user);
    setIsModalOpen(true);
    
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setTwoFaModel(false);
    clearOtpFields();
  };
  const openTwoFaModel = async () => {
    try {
      // Reset OTP state and clear input fields
      setOtp(Array(6).fill('')); // Reset OTP state
      setTwoFaModel(false); // Ensure the modal is closed before reopening
      setTimeout(() => {
        setTwoFaModel(true); // Open the Two-Factor Authentication model
        clearOtpFields();
      }, 0);

      // Make the OTP request
      const response = await axios.get(`${fwinBackendUrl}/admin/send-otp`, {
        params: {
          email: currentUser.email, // Pass the user's email or other necessary parameter
        },
      });

      // Handle successful OTP request
      console.log('OTP sent successfully:', response.data);
      // You might want to display a success message or perform other actions

    } catch (err) {
      // Handle error in OTP request
      console.error('Error sending OTP:', err);
      // Optionally, you might want to provide feedback to the user
    }
  };
  // Function to clear OTP input fields
  const clearOtpFields = () => {
    // Clear OTP inputs by directly manipulating the refs
    inputRefs.current.forEach(ref => {
      if (ref) {
        ref.value = '';
      }
    });
  };
  const handleNumberChange = (number) => {
    setSelectedNumber(number);
  };
  const handle2FaChange = (e, index) => {
    const value = e.target.value;
    if (value.match(/[0-9]/)) {
      // Update OTP state
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input if not the last one
      if (index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    } else {
      // Handle invalid input (not a number)
      e.target.value = '';
    }
  };
  // Handle the submit of the OTP form
  const handle2FaSubmit = async (e) => {
    e.preventDefault();
    const otpString = otp.join('');

    try {
      const response = await axios.get(`${fwinBackendUrl}/admin/verify-otp`, {
        params: {
          email: currentUser.email, // Replace with actual email
          otp: otpString,
          amount : Number(selectedNumber),
          user : selectedUser.email
        },
      });
      alert('OTP is valid');
    } catch (error) {
      alert('Invalid or expired OTP');
    }
    clearOtpFields();
  };
  // Focus on the previous input when current one is empty
  const handle2FaKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp[index] === '') {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };
  useEffect(() => {
    // Initialize inputRefs
    inputRefs.current = inputRefs.current.slice(0, 6);
  }, [inputRefs.current]);

  if (loading) return <div>Loading...</div>; 
  if (error) return <div>Error: {error}</div>;

   // Function to handle tab change
   const handleTabChange = (tab) => {
    setActiveTab(tab);
    // Reset pagination when switching tabs
    
};

  return (
    <>
    <section>
                <div className='space-y-5'>
                    <div className="flex items-center gap-2">
                        <div className="flex h-6 w-6 items-center justify-center rounded-md border">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="lucide lucide-club h-4 w-4"
                            >
                                <path d="M17.28 9.05a5.5 5.5 0 1 0-10.56 0A5.5 5.5 0 1 0 12 17.66a5.5 5.5 0 1 0 5.28-8.6Z"></path>
                                <path d="M12 17.66L12 22"></path>
                            </svg>
                        </div>
                        <h3>Game History</h3>
                    </div>

                    <div className='flex items-center'>
                        <div className='themeSwitcherTwo p-2 shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-md bg-gray-200'>
                            <input
                                type='checkbox'
                                className='sr-only'
                                checked={activeTab === 'all'}
                                onChange={() => handleTabChange(activeTab === 'live' ? 'all' : 'live')}
                            />
                            <span
                                className={`flex items-center space-x-[6px] rounded py-2 px-[18px] text-sm font-medium ${activeTab === 'all' ? 'text-primary bg-[#f4f7ff]' : 'text-body-color'}`}
                                onClick={() => handleTabChange('all')}
                            >
                                All Users
                            </span>
                            <span
                                className={`flex items-center space-x-[6px] rounded py-2 px-[18px] text-sm font-medium ${activeTab === 'live' ? 'text-primary bg-[#f4f7ff]' : 'text-body-color'}`}
                                onClick={() => handleTabChange('live')}
                            >
                                Live Players
                            </span>
                           
                        </div>
                    </div>
                </div>
    </section>
    <section>
                <div className='py-4'>
                    {(activeTab === 'all' || activeTab === 'live') && (
                      <div>
                        <div>
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
            <tr className="w-full border-b">
              <th className="px-6 py-4  text-left text-sm font-medium ">User</th>
              <th className="px-6 py-4  text-left text-sm font-medium ">Currency</th>
              <th className="px-6 py-4  text-left text-sm font-medium ">Joined At</th>
              <th className="px-6 py-4  text-left text-sm font-medium ">Balance</th>
              <th className="px-6 py-4  text-left text-sm font-medium ">Edit</th>
            </tr>
          </thead>
          <tbody className='bg-white divide-y divide-gray-200'>
            {userData.map((user, index) => (
              <tr key={index} className="border font-normal text-left">
                <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-500">{user.email}</td>
                <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-500">{user.currency}</td>
                <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-500">{user.createdAt}</td>
                <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-500">{user.balance}</td>
                <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-500 curson-pointer">
                  <div className='flex text-blue-600 gap-4'>
                    <div className='edit-icon cursor-pointer' onClick={() => handleEditClick(user, index)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                    </svg>
                    </div>
                    <div className='info-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                    </svg>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
  <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto bg-gray-900 bg-opacity-50">
    <div className="relative p-4 w-full max-w-2xl">
      <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
            {twoFaModel ? 'Two-Factor Authentication' : 'Edit User'}
          </h3>
        </div>
        {twoFaModel ? (
          <div className="p-4">
            <p className='text-md text-black text-center font-light'>Authenticate to add balance to user</p>
            <div>

            <form className="max-w-sm mx-auto my-4" onSubmit={handle2FaSubmit}>
      <div className="flex mb-2 space-x-2 rtl:space-x-reverse justify-center">
        {otp.map((value, index) => (
          <div key={index}>
            <label htmlFor={`code-${index + 1}`} className="sr-only">
              {`Code ${index + 1}`}
            </label>
            <input
              type="text"
              maxLength="1"
              value={value}
              onChange={(e) => handle2FaChange(e, index)}
              onKeyDown={(e) => handle2FaKeyDown(e, index)}
              ref={(el) => (inputRefs.current[index] = el)}
              id={`code-${index + 1}`}
              className="block w-9 h-9 py-3 text-sm font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              required
            />
          </div>
        ))}
      </div>
      <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">
        Please introduce the 6-digit code we sent via email.
      </p>
      <button
        type="submit"
        className="mt-6 text-white w-full bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
      >
        Confirm
      </button>
            </form>
            </div>
           
          </div>
        ) : (
          <div className='p-4'>
            {selectedUser && (
              <>
                <p className="text-lg font-bold mb-4 text-left text-gray-500 font-light">
                  User ID: <span className='text-xs text-black font-light'>{selectedUser.userId}</span>
                </p>
                <p className="text-lg font-bold mb-4 text-left text-gray-500 font-light">
                  Email: <span className='text-xs text-black font-light'>{selectedUser.email}</span>
                </p>
                <div className='flex items-center'>
                  <p className="text-lg font-bold text-left text-gray-500 font-light">
                    User balance: <span className='text-lg text-black font-light'>{selectedUser.balance}</span>
                  </p>
                  <img className='h-[22px]' src={euro} alt="euro" />
                </div>
              </>
            )}
            <div className="p-4 text-left">
              <label htmlFor="balance" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Add balance
              </label>
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={selectedNumber}
                onChange={(e) => handleNumberChange(e.target.value)}
              />
            </div>
            <div className='flex w-3/5 px-4 pb-8'>
            <div role="radiogroup" aria-required="false" dir="ltr" class="flex justify-between gap-1" tabindex="0" >
                                                         
                                                         <div class="space-y-2">
                                                             <label class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 [&amp;:has([data-state=checked])>div]:border-foreground [&amp;:has([data-state=checked])>div]:bg-foreground [&amp;:has([data-state=checked])>div]:text-background" for=":r7b:-form-item">
                                                                 <button onClick={() => handleNumberChange(5)} type="button" role="radio" aria-checked="false" data-state="unchecked" value="1" class="aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 sr-only" id=":r7b:-form-item" aria-describedby=":r7b:-form-item-description" aria-invalid="false" tabindex="-1" data-radix-collection-item="">
                                                                 </button>
                                                                 <input aria-hidden="true" tabindex="-1" type="radio" value="1" className='transform -translate-x-full absolute pointer-events-none opacity-0 m-0 w-16 h-16' />
                                                                 <div className={`cursor-pointer rounded-lg border border-border px-2 py-1 hover:bg-border sm:px-3 ${selectedNumber === 5 ? 'bg-black text-white' : ''}`}>
                                                                     <h3 class="text-center text-xl">
                                                                         5
                                                                     </h3>
                                                                 </div>
                                                             </label>
                                                         </div>
                                                         <div class="space-y-2">
                                                             <label class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 [&amp;:has([data-state=checked])>div]:border-foreground [&amp;:has([data-state=checked])>div]:bg-foreground [&amp;:has([data-state=checked])>div]:text-background" for=":r7d:-form-item">
                                                                 <button onClick={() => handleNumberChange(10)} type="button" role="radio" aria-checked="false" data-state="unchecked" value="2" class="aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 sr-only" id=":r7d:-form-item" aria-describedby=":r7d:-form-item-description" aria-invalid="false" tabindex="-1" data-radix-collection-item="">
                                                                     </button>
                                                                     <input aria-hidden="true" tabindex="-1" type="radio" value="2" className='transform -translate-x-full absolute pointer-events-none opacity-0 m-0 w-16 h-16' />
                                                                     <div className={`cursor-pointer rounded-lg border border-border px-2 py-1 hover:bg-border sm:px-3 ${selectedNumber === 10 ? 'bg-black text-white' : ''}`}>
                                                                         <h3 class="text-center text-xl">10</h3>
                                                                     </div>
                                                             </label>
                                                         </div>
                                                         <div class="space-y-2">
                                                             <label class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 [&amp;:has([data-state=checked])>div]:border-foreground [&amp;:has([data-state=checked])>div]:bg-foreground [&amp;:has([data-state=checked])>div]:text-background" for=":r7f:-form-item">
                                                                 <button onClick={() => handleNumberChange(20)} type="button" role="radio" aria-checked="false" data-state="unchecked" value="3" class="aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 sr-only" id=":r7f:-form-item" aria-describedby=":r7f:-form-item-description" aria-invalid="false" tabindex="-1" data-radix-collection-item=""></button>
                                                                 <input aria-hidden="true" tabindex="-1" type="radio" value="3" className='transform -translate-x-full absolute pointer-events-none opacity-0 m-0 w-16 h-16' />
                                                                 <div className={`cursor-pointer rounded-lg border border-border px-2 py-1 hover:bg-border sm:px-3 ${selectedNumber === 20 ? 'bg-black text-white' : ''}`}>
                                                                     <h3 class="text-center text-xl">20</h3>
                                                                 </div>
                                                             </label>
                                                         </div>
                                                         <div class="space-y-2">
                                                             <label class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 [&amp;:has([data-state=checked])>div]:border-foreground [&amp;:has([data-state=checked])>div]:bg-foreground [&amp;:has([data-state=checked])>div]:text-background" for=":r7h:-form-item">
                                                                 <button onClick={() => handleNumberChange(50)} type="button" role="radio" aria-checked="false" data-state="unchecked" value="4" class="aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 sr-only" id=":r7h:-form-item" aria-describedby=":r7h:-form-item-description" aria-invalid="false" tabindex="0" data-radix-collection-item="">
                                                                     <span data-state="checked" class="flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle h-2.5 w-2.5 fill-current text-current"><circle cx="12" cy="12" r="10"></circle></svg></span></button><input aria-hidden="true" tabindex="-1" type="radio" value="4" className='transform -translate-x-full absolute pointer-events-none opacity-0 m-0 w-16 h-16' checked="" />
                                                                     <div className={`cursor-pointer rounded-lg border border-border px-2 py-1 hover:bg-border sm:px-3 ${selectedNumber === 50 ? 'bg-black text-white' : ''}`}>
                                                                         <h3 class="text-center text-xl">50</h3>
                                                                     </div>
                                                             </label>
                                                         </div>
                                                     </div>
            </div>
          </div>
        )}
        <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
          <button
            onClick={twoFaModel ? closeModal : openTwoFaModel}
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            {twoFaModel ? 'Close' : 'Save'}
          </button>
          {!twoFaModel && (
            <button
              onClick={closeModal}
              type="button"
              className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            >
              Cancel
            </button>
          )}
        </div>
      </div>
    </div>
  </div>
)}
</div>

)}
    </div>
 
    </section>
    </>
    
  );
}

export default Users; 
