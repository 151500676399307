import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../Components/Auth/AuthContext'; 

const Withdraw = () => {
    const { fwinBackendUrl } = useAuth();
    const [withdrawals, setWithdrawals] = useState([]);

    useEffect(() => {
        const fetchWithdrawals = async () => {
            try {
                const response = await axios.get(`${fwinBackendUrl}/admin/withdrawals`);
                setWithdrawals(response.data);
            } catch (error) {
                console.error('Error fetching withdrawals:', error);
            }
        };

        fetchWithdrawals();
    }, []); 

    return (
        <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300">
                <thead>
                    <tr>
                        <th className="py-2 px-4 border-b">Date</th>
                        <th className="py-2 px-4 border-b">User</th>
                        <th className="py-2 px-4 border-b">Amount</th>
                        <th className="py-2 px-4 border-b">Usdt Amount</th>
                        <th className="py-2 px-4 border-b">Wallet</th>
                        <th className="py-2 px-4 border-b">transactionId</th>
                        <th className="py-2 px-4 border-b">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {withdrawals.map((withdrawal, index) => (
                        <tr key={index}>
                            <td className="py-2 px-4 border-b">{new Date(withdrawal.date).toLocaleString()}</td>
                            <td className="py-2 px-4 border-b">{withdrawal.email}</td>
                            <td className="py-2 px-4 border-b">{withdrawal.amount}</td>
                            <td className="py-2 px-4 border-b">{withdrawal.usdtAmount}</td>
                            <td className="py-2 px-4 border-b">{withdrawal.address}</td>
                            <td className="py-2 px-4 border-b">{withdrawal.transactionId}</td>
                            <td className="py-2 px-4 border-b">{withdrawal.status}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Withdraw;
