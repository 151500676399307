import React, { Component, useState, useRef, useEffect } from 'react';
import fwinlogo from '../Assets/fwin.jpg';
import Footer from '../Components/Footer';
import Dashboard from './Pages/Dashboard';
import Users from './Pages/Users';
import Deposits from './Pages/Deposits';
import Withdraw from './Pages/Withdraw';
import Gamehistory from './Pages/GameHistory';
import { useAuth } from '../Components/Auth/AuthContext';  
import { auth } from '../firebase';
import { signOut } from "firebase/auth"; 


const Admin = () => {
   const { currentUser } = useAuth();
  const [adminEmails] = useState([
    "abhilashalphonse2561999@gmail.com",
    "go4nilesh10@gmail.com"
  ]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedGame, setSelectedGame] = useState("Dashboard");
  const [isAdmin, setIsAdmin] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    setIsAdmin(adminEmails.includes(currentUser?.email));
  }, [currentUser, adminEmails]);

  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState);
  };

  const handleSelectGame = (game) => {
    setSelectedGame(game);
    setIsMenuOpen(false);
  };

  const handleLogout = async () => {
   await signOut(auth);
 };



    return ( <>
     {isAdmin? (
         <div>
           
               <div>
               <nav class="bg-white md:fixed md:h-20 dark:bg-gray-900 w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600">
                  <div class="max-w-screen flex flex-wrap items-center justify-between mx-4 py-4 h-full">
                     <a href="/" class="flex items-center space-x-3 rtl:space-x-reverse">
                        <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
                           <img width={96} src={fwinlogo} />
                        </span>
                     </a>
                     <div class="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
                     
                           {isAdmin ? (
                               <button onClick={handleLogout}  type="button" class="text-white font-bold bg-[#3230C4] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300  rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                               Logout
                              </button>
                           ) : (
                              <button  type="button" class="text-white bg-[#3230C4] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                              Login
                              </button>
                           )}
                        <button onClick={toggleMenu}  aria-controls="default-sidebar" type="button" class="inline-flex items-center p-2  ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                           <span class="sr-only">Open sidebar</span>
                           <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                              <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                           </svg>
                        </button>
                     </div>
                  </div>
               </nav>
               {/* Mobile Only!!!! */}
               <aside ref={menuRef} id="default-sidebar" class={`fixed top-0 left-0 w-72 h-screen md:hidden transition-transform ${isMenuOpen ? '' : '-translate-x-full'} sm:translate-x-0 bg-gray-50 dark:bg-gray-800 z-50`} aria-label="Sidebar">
                  <div class="h-full p-8 overflow-y-auto bg-gray-50 dark:bg-gray-800">
                     <ul class="space-y-2 font-bold flex flex-col h-full">
                        <li onClick={() => handleSelectGame('Dashboard')}>
                           <a href="#" class={`flex items-center p-2 text-gray-900  rounded-lg group ${selectedGame === "Dashboard" ? 'bg-black text-white' : ''}`}>
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 rounded-md border bg-white dark:border-none dark:bg-none"><rect width="24" height="24" rx="6" fill="white"></rect><path d="M19.2818 8.41506L13.8883 3.68672C13.3938 3.24449 12.7537 3 12.0904 3C11.4271 3 10.787 3.24449 10.2926 3.68672L4.89901 8.41506C4.61348 8.67042 4.38563 8.98368 4.23063 9.33398C4.07563 9.68428 3.99704 10.0636 4.00009 10.4466V18.3032C4.00009 19.0185 4.28421 19.7044 4.78995 20.2101C5.29569 20.7159 5.98163 21 6.69686 21H17.4839C18.1992 21 18.8851 20.7159 19.3909 20.2101C19.8966 19.7044 20.1807 19.0185 20.1807 18.3032V10.4376C20.1825 10.0561 20.1033 9.67853 19.9483 9.32988C19.7934 8.98122 19.5662 8.66942 19.2818 8.41506ZM13.8883 19.2022H10.2926V14.7075C10.2926 14.4691 10.3873 14.2405 10.5558 14.0719C10.7244 13.9033 10.9531 13.8086 11.1915 13.8086H12.9893C13.2277 13.8086 13.4564 13.9033 13.625 14.0719C13.7935 14.2405 13.8883 14.4691 13.8883 14.7075V19.2022ZM18.3829 18.3032C18.3829 18.5416 18.2882 18.7703 18.1196 18.9389C17.951 19.1074 17.7224 19.2022 17.4839 19.2022H15.6861V14.7075C15.6861 13.9923 15.402 13.3064 14.8962 12.8006C14.3905 12.2949 13.7046 12.0108 12.9893 12.0108H11.1915C10.4763 12.0108 9.79032 12.2949 9.28457 12.8006C8.77883 13.3064 8.49471 13.9923 8.49471 14.7075V19.2022H6.69686C6.45845 19.2022 6.2298 19.1074 6.06122 18.9389C5.89264 18.7703 5.79793 18.5416 5.79793 18.3032V10.4376C5.7981 10.31 5.82544 10.1839 5.87813 10.0676C5.93083 9.95138 6.00768 9.84768 6.10357 9.76345L11.4971 5.04409C11.6612 4.89998 11.872 4.8205 12.0904 4.8205C12.3088 4.8205 12.5197 4.89998 12.6837 5.04409L18.0772 9.76345C18.1731 9.84768 18.25 9.95138 18.3027 10.0676C18.3554 10.1839 18.3827 10.31 18.3829 10.4376V18.3032Z" fill="#2F3A42"></path></svg>

                              <span class="ms-3">Dashboard</span>
                           </a>
                        </li>
                        <li onClick={() => handleSelectGame('Users')}>
                           <a href="#" class={`flex items-center p-2 text-gray-900  rounded-lg group ${selectedGame === "Users" ? 'bg-black text-white' : ''}`}>
                           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
</svg>


                              <span class="ms-3">Users</span>
                           </a>
                        </li>
                        <li onClick={() => handleSelectGame('Deposits')}>
                           <a href="#" class={`flex items-center p-2 text-gray-900  rounded-lg group ${selectedGame === "Deposits" ? 'bg-black text-white' : ''}`}>
                           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
</svg>

                              <span class="ms-3">Deposits</span>
                           </a>
                        </li>
                        <li onClick={() => handleSelectGame('Withdraw')}>
                           <a href="#" class={`flex items-center p-2 text-gray-900  rounded-lg group ${selectedGame === "Withdraw" ? 'bg-black text-white' : ''}`}>
                           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6 9 12.75l4.286-4.286a11.948 11.948 0 0 1 4.306 6.43l.776 2.898m0 0 3.182-5.511m-3.182 5.51-5.511-3.181" />
</svg>


                              <span class="ms-3">Withdraw</span>
                           </a>
                        </li>
                        <li onClick={() => handleSelectGame('History')}>
                           <a href="#" class={`flex items-center p-2 text-gray-900  rounded-lg group ${selectedGame === "History" ? 'bg-black text-white' : ''}`}>
                           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
</svg>


                              <span class="ms-3">History</span>
                           </a>
                        </li>
                     </ul>
                  </div>
               </aside>
               {/* Desktop */}
               <aside id="default-sidebar" class="border-r border-gray-200 fixed pt-20 top-0 left-0 z-4 w-60 h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar">
                  <div class="h-full px-4 py-4 overflow-y-auto ">
                     <ul class="space-y-2 font-bold flex flex-col h-full">
                        <li onClick={() => handleSelectGame('Dashboard')}>
                           <a href="#" class={`flex items-center p-2 text-gray-900  rounded-lg group ${selectedGame === "Dashboard" ? 'bg-black text-white' : ''}`}>
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 rounded-md border bg-white dark:border-none dark:bg-none"><rect width="24" height="24" rx="6" fill="white"></rect><path d="M19.2818 8.41506L13.8883 3.68672C13.3938 3.24449 12.7537 3 12.0904 3C11.4271 3 10.787 3.24449 10.2926 3.68672L4.89901 8.41506C4.61348 8.67042 4.38563 8.98368 4.23063 9.33398C4.07563 9.68428 3.99704 10.0636 4.00009 10.4466V18.3032C4.00009 19.0185 4.28421 19.7044 4.78995 20.2101C5.29569 20.7159 5.98163 21 6.69686 21H17.4839C18.1992 21 18.8851 20.7159 19.3909 20.2101C19.8966 19.7044 20.1807 19.0185 20.1807 18.3032V10.4376C20.1825 10.0561 20.1033 9.67853 19.9483 9.32988C19.7934 8.98122 19.5662 8.66942 19.2818 8.41506ZM13.8883 19.2022H10.2926V14.7075C10.2926 14.4691 10.3873 14.2405 10.5558 14.0719C10.7244 13.9033 10.9531 13.8086 11.1915 13.8086H12.9893C13.2277 13.8086 13.4564 13.9033 13.625 14.0719C13.7935 14.2405 13.8883 14.4691 13.8883 14.7075V19.2022ZM18.3829 18.3032C18.3829 18.5416 18.2882 18.7703 18.1196 18.9389C17.951 19.1074 17.7224 19.2022 17.4839 19.2022H15.6861V14.7075C15.6861 13.9923 15.402 13.3064 14.8962 12.8006C14.3905 12.2949 13.7046 12.0108 12.9893 12.0108H11.1915C10.4763 12.0108 9.79032 12.2949 9.28457 12.8006C8.77883 13.3064 8.49471 13.9923 8.49471 14.7075V19.2022H6.69686C6.45845 19.2022 6.2298 19.1074 6.06122 18.9389C5.89264 18.7703 5.79793 18.5416 5.79793 18.3032V10.4376C5.7981 10.31 5.82544 10.1839 5.87813 10.0676C5.93083 9.95138 6.00768 9.84768 6.10357 9.76345L11.4971 5.04409C11.6612 4.89998 11.872 4.8205 12.0904 4.8205C12.3088 4.8205 12.5197 4.89998 12.6837 5.04409L18.0772 9.76345C18.1731 9.84768 18.25 9.95138 18.3027 10.0676C18.3554 10.1839 18.3827 10.31 18.3829 10.4376V18.3032Z" fill="#2F3A42"></path></svg>
                              <span class="ms-3">Dashboard</span>
                           </a>
                        </li>
                        <li onClick={() => handleSelectGame('Users')}>
                           <a href="#" class={`flex items-center p-2 text-gray-900  rounded-lg group ${selectedGame === "Users" ? 'bg-black text-white' : ''}`}>
                           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
</svg>

                              <span class="ms-3">Users</span>
                           </a>
                        </li>
                        <li onClick={() => handleSelectGame('Deposits')}>
                           <a href="#" class={`flex items-center p-2 text-gray-900  rounded-lg group ${selectedGame === "Deposits" ? 'bg-black text-white' : ''}`}>
                           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
</svg>

                              <span class="ms-3">Deposits</span>
                           </a>
                        </li>
                        <li onClick={() => handleSelectGame('Withdraw')}>
                           <a href="#" class={`flex items-center p-2 text-gray-900  rounded-lg group ${selectedGame === "Withdraw" ? 'bg-black text-white' : ''}`}>
                           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6 9 12.75l4.286-4.286a11.948 11.948 0 0 1 4.306 6.43l.776 2.898m0 0 3.182-5.511m-3.182 5.51-5.511-3.181" />
</svg>
<span class="ms-3">Withdraw</span>
                           </a>
                        </li>
                        <li onClick={() => handleSelectGame('History')}>
                           <a href="#" class={`flex items-center p-2 text-gray-900  rounded-lg group ${selectedGame === "History" ? 'bg-black text-white' : ''}`}>
                           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
</svg>

                              <span class="ms-3">History</span>
                           </a>
                        </li>
                     </ul>
                  </div>
               </aside>
               {/* Homepage Content */}
               <div class="md:ml-60 md:pt-20">
               <div className='px-4 py-5 lg:px-8'>
                {selectedGame === 'Dashboard' && <Dashboard />}
                {selectedGame === 'Users' && <Users />}
                {selectedGame === 'Deposits' && <Deposits />}
                {selectedGame === 'Withdraw' && <Withdraw />}
                {selectedGame === 'History' && <Gamehistory />}
                </div>
                  <Footer />
               </div>
            </div>
            {isMenuOpen && <div className="fixed inset-0 bg-gray-900 opacity-50 z-40" onClick={toggleMenu}></div>}
         
            
           
            
         </div>
            ):(
            <span>Not Authorized</span>
          )}
    </> );
}
 
export default Admin; 