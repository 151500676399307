import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../Components/Auth/AuthContext';

const Gamehistory = () => {
    const { fwinBackendUrl } = useAuth();
    const [slotsData, setSlotsData] = useState([]);
    const [liveData, setLiveData] = useState([]);
    const [fwinData, setFwinData] = useState([]);
    const [activeTab, setActiveTab] = useState('slots');

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); // Adjust the number of items per page as needed

    useEffect(() => {
        const fetchSlotsData = async () => {
            try {
                const response = await axios.get(`${fwinBackendUrl}/admin/slots-history-report`);
                setSlotsData(response.data);
            } catch (err) {
                console.error('Error fetching data:', err);
            }
        };
        const fetchLiveData = async () => {
            try {
                const response = await axios.get(`${fwinBackendUrl}/admin/live-history-report`);
                setLiveData(response.data);
            } catch (err) {
                console.error('Error fetching data:', err);
            }
        };
        const fetchFwinData = async () => {
            try {
                const response = await axios.get(`${fwinBackendUrl}/admin/fwin-originals-history-report`);
                setFwinData(response.data);
            } catch (err) {
                console.error('Error fetching FWIN data:', err);
            }
        };

        fetchSlotsData();
        fetchLiveData();
        fetchFwinData();
    }, [fwinBackendUrl]);

    // Calculate total pages
    const totalPages = Math.ceil(
        (activeTab === 'slots' ? slotsData :
         activeTab === 'live' ? liveData : 
         fwinData).length / itemsPerPage
    );

    // Slice data for current page based on the active tab
    const currentData = (activeTab === 'slots' ? slotsData :
                        activeTab === 'live' ? liveData : 
                        fwinData).slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    // Pagination handler functions
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Function to handle tab change
    const handleTabChange = (tab) => {
        setActiveTab(tab);
        // Reset pagination when switching tabs
        setCurrentPage(1);
    };

    return (
        <>
            <section>
                <div className='space-y-5'>
                    <div className="flex items-center gap-2">
                        <div className="flex h-6 w-6 items-center justify-center rounded-md border">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="lucide lucide-club h-4 w-4"
                            >
                                <path d="M17.28 9.05a5.5 5.5 0 1 0-10.56 0A5.5 5.5 0 1 0 12 17.66a5.5 5.5 0 1 0 5.28-8.6Z"></path>
                                <path d="M12 17.66L12 22"></path>
                            </svg>
                        </div>
                        <h3>Game History</h3>
                    </div>

                    <div className='flex items-center'>
                        <div className='themeSwitcherTwo p-2 shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-md bg-gray-200'>
                            <input
                                type='checkbox'
                                className='sr-only'
                                checked={activeTab === 'live'}
                                onChange={() => handleTabChange(activeTab === 'live' ? 'slots' : 'live')}
                            />
                            <span
                                className={`flex items-center space-x-[6px] rounded py-2 px-[18px] text-sm font-medium ${activeTab === 'slots' ? 'text-primary bg-[#f4f7ff]' : 'text-body-color'}`}
                                onClick={() => handleTabChange('slots')}
                            >
                                Slots
                            </span>
                            <span
                                className={`flex items-center space-x-[6px] rounded py-2 px-[18px] text-sm font-medium ${activeTab === 'live' ? 'text-primary bg-[#f4f7ff]' : 'text-body-color'}`}
                                onClick={() => handleTabChange('live')}
                            >
                                Live
                            </span>
                            <span
                                className={`flex items-center space-x-[6px] rounded py-2 px-[18px] text-sm font-medium ${activeTab === 'fwin' ? 'text-primary bg-[#f4f7ff]' : 'text-body-color'}`}
                                onClick={() => handleTabChange('fwin')}
                            >
                                Fwin Originals
                            </span>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='py-4'>
                    {(activeTab === 'slots' || activeTab === 'live' || activeTab === 'fwin') && (
                        <div>
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date Time</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Provider</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Game Code</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Bet Amount</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Win Amount</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Start Balance</th>
                                        {activeTab === 'fwin' && (
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                        )}
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">End Balance</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {currentData.map((item, index) => (
                                        <tr key={index}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{new Date(item.date).toLocaleString()}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.user}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.provider}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.gameCode}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.betAmount}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.winAmount}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.startBalance?.toFixed(2)}</td>
                                            {activeTab === 'fwin' && (
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.status}</td>
                                            )}
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.endBalance?.toFixed(2)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            {/* Pagination Controls */}
                            <div className="flex justify-between items-center mt-4">
                                <button
                                    onClick={handlePreviousPage}
                                    disabled={currentPage === 1}
                                    className="px-4 py-2 bg-gray-200 rounded-md"
                                >
                                    Previous
                                </button>
                                <span className="text-sm font-medium">
                                    Page {currentPage} of {totalPages}
                                </span>
                                <button
                                    onClick={handleNextPage}
                                    disabled={currentPage === totalPages}
                                    className="px-4 py-2 bg-gray-200 rounded-md"
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </>
    );
};

export default Gamehistory;
