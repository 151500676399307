import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import { useAuth } from '../Auth/AuthContext';

const cryptocurrencies = [
  {
    name: 'Tron',
    symbol: 'TRX',
    image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/trc20.svg',
    networks: [
        { name: 'Mainnet', image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/trc20.svg' },
    ]
  },
  {
    name: 'USDT',
    symbol: 'USDT',
    image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/usdt.svg',
    networks: [
        { name: 'TRC-20', image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/trc20.svg' },
       
    ]
},
   
];
{/*  {
        name: 'Bitcoin',
        symbol: 'BTC',
        image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/btc.svg',
        networks: [
            { name: 'Mainnet', image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/btc.svg' },
        ]
    },
    {
      name: 'Tron',
      symbol: 'TRX',
      image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/trc20.svg',
      networks: [
          { name: 'Mainnet', image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/trc20.svg' },
      ]
    },
    {
        name: 'Ethereum',
        symbol: 'ETH',
        image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/eth.svg',
        networks: [
            { name: 'Mainnet', image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/eth.svg' },
            
        ]
    },
    {
        name: 'Litecoin',
        symbol: 'LTC',
        image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/ltc.svg',
        networks: [
            { name: 'Mainnet', image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/ltc.svg' },
        ]
    },
    {
      name: 'BNB',
      symbol: 'BNB',
      image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/bsc-bep-20.svg',
      networks: [
          { name: 'BSC', image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/bsc-bep-20.svg' }
      ]
    },
    {
      name: 'Polygon',
      symbol: 'MATIC',
      image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/polygon.svg',
      networks: [
          { name: 'Polygon', image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/polygon.svg' }
      ]
    },
    {
      name: 'Solana',
      symbol: 'SOL',
      image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/sol.svg',
      networks: [
          { name: 'Solana', image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/sol.svg' }
      ]
    },
    {
        name: 'USDT',
        symbol: 'USDT',
        image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/usdt.svg',
        networks: [
            { name: 'ERC-20', image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/eth.svg' },
            { name: 'TRC-20', image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/trc20.svg' },
            { name: 'BSC', image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/bsc-bep-20.svg' },
            { name: 'Polygon', image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/polygon.svg' },
            { name: 'Solana', image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/sol.svg' },
        ]
    },
    {
        name: 'USDC',
        symbol: 'USDC',
        image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/usdc.svg',
        networks: [
            { name: 'ERC-20', image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/eth.svg' },
            { name: 'BNB', image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/bsc-bep-20.svg' },
            { name: 'Polygon', image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/polygon.svg' },
            { name: 'Solana', image: 'https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/sol.svg' },
        ]
    },
    */}

const Crypto = () => {
    const { currentUser, email, country, fwinBackendUrl } = useAuth();
    const [wallets, setWallets] = useState(null);
    const defaultCrypto = cryptocurrencies[0];
    const defaultNetwork = defaultCrypto.networks[0];
    const [selectedCrypto, setSelectedCrypto] = useState(defaultCrypto);
    const [selectedNetwork, setSelectedNetwork] = useState(defaultNetwork);
    const [walletAddress, setWalletAddress] = useState(''); 
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
      if (email) {
          fetchUserWallet(email);
      }
      
  }, [currentUser]);

    const handleCryptoSelect = (crypto) => {
        setSelectedCrypto(crypto);
        setSelectedNetwork(crypto.networks[0]);
        document.getElementById("cryptoDropdown").classList.toggle("hidden");
    };
    const handleNetworkSelect = (network) => {
        setSelectedNetwork(network);
        document.getElementById("networkDropdown").classList.toggle("hidden");
    };
    const fetchUserWallet = async (email) => {
      try {
        const response = await fetch(`${fwinBackendUrl}/payments/deposit-address/${email}`);
        if (!response.ok) {
          throw new Error('User not found or other server error');
        }
        const data = await response.json();
        setWallets(data);
        console.log(data)
        setWalletAddress(data.tron)
        console.log(wallets);
      } catch (err) {
        setWallets(null); 
      }
    }; 
    const handleCopy = async () => {
      try {
        if (navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
          // Try to use the modern Clipboard API
          await navigator.clipboard.writeText(wallets.tron);
        } else {
          // Fallback for older browsers or if Clipboard API fails
          const textArea = document.createElement('textarea');
          textArea.value = wallets.tron;
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          document.execCommand('copy');
          document.body.removeChild(textArea);
        }
    
        // Set the copied state and reset after 2 seconds
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      } catch (err) {
        console.error('Failed to copy text:', err);
      }
    };
  

    

   

    return (
      <>
        <div className="relative flex space-x-4"> 
          {/* Cryptocurrency Dropdown */}
          <div className="relative w-1/2">
            <button
              id="cryptoDropdownButton"
              data-dropdown-toggle="cryptoDropdown"
              className="w-full text-white bg-blue-700  hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex flex-col items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              type="button"
              onClick={() =>
                document
                  .getElementById("cryptoDropdown")
                  .classList.toggle("hidden")
              }
            >
              <span className="text-xs">Select Currency</span>
              <div className="flex items-center">
                <img
                  src={selectedCrypto.image}
                  alt={selectedCrypto.name}
                  className="w-6 h-6 mr-2"
                />
                {selectedCrypto.name}
                <svg
                  className="w-2.5 h-2.5 ms-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </div>
            </button>

            <div
              id="cryptoDropdown"
              className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 absolute"
            >
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="cryptoDropdownButton"
              >
                {cryptocurrencies.map((crypto) => (
                  <li
                    key={crypto.symbol}
                    onClick={() => handleCryptoSelect(crypto)}
                  >
                    <a
                      href="#"
                      className="flex items-center block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      <img
                        src={crypto.image}
                        alt={crypto.name}
                        className="w-6 h-6 mr-2"
                      />
                      {crypto.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Network Dropdown */}
          <div className="relative w-1/2">
            <button
              id="networkDropdownButton"
              data-dropdown-toggle="networkDropdown"
              className="w-full text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex flex-col items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              type="button"
              onClick={() =>
                document
                  .getElementById("networkDropdown")
                  .classList.toggle("hidden")
              }
            >
              <span className="text-xs">Select Network</span>
              <div className="flex items-center">
                <img
                  src={selectedNetwork.image}
                  alt={selectedNetwork.name}
                  className="w-6 h-6 mr-2"
                />
                {selectedNetwork.name}
                <svg
                  className="w-2.5 h-2.5 ms-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </div>
            </button>

            <div
              id="networkDropdown"
              className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 absolute"
            >
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="networkDropdownButton"
              >
                {selectedCrypto.networks.map((network) => (
                  <li
                    key={network.name}
                    onClick={() => handleNetworkSelect(network)}
                  >
                    <a
                      href="#"
                      className="flex items-center block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      <img
                        src={network.image}
                        alt={network.name}
                        className="w-6 h-6 mr-2"
                      />
                      {network.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* Deposit Address */}

        <div
          className="relative my-5 mx-[23px] border-t border-solid"
          style={{
            borderImageSlice: 1,
            borderImageSource:
              "linear-gradient(90deg, rgba(67, 83, 109, 0), #43536d, rgba(67, 83, 109, 0))",
          }}
        ></div>

         <div className="flex gap-2">
          <div className="col flex-grow">
            <div>
              <p className="text-xs text-black font-bold text-left pb-2">
                Your {selectedCrypto.name} deposit address
              </p>
            </div>
            <div class="w-full">
              <div class="relative">
                <label for="npm-install-copy-text" class="sr-only">
                  Label
                </label>
                <input
                  id="npm-install-copy-text"
                  type="text"
                  class="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={walletAddress}
                  disabled
                  readonly
                />
                <button
                  onClick={handleCopy}
                  data-copy-to-clipboard-target="npm-install-copy-text"
                  class="absolute end-2.5 top-1/2 -translate-y-1/2 text-gray-900 dark:text-gray-400 hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-600 dark:hover:bg-gray-700 rounded-lg py-2 px-2.5 inline-flex items-center justify-center bg-white border-gray-200 border"
                >
                  <span id="default-message"  className={`inline-flex items-center ${isCopied ? 'hidden' : ''}`}>
                    <svg
                      class="w-3 h-3 me-1.5"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 18 20"
                    >
                      <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
                    </svg>
                    <span class="text-xs font-semibold">Copy</span>
                  </span>
                  <span
                    id="success-message"
                    className={`inline-flex items-center ${!isCopied ? 'hidden' : ''}`}
                  >
                    <svg
                      class="w-3 h-3 text-blue-700 dark:text-blue-500 me-1.5"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 16 12"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M1 5.917 5.724 10.5 15 1.5"
                      />
                    </svg>
                    <span class="text-xs font-semibold text-blue-700 dark:text-blue-500">
                      Copied
                    </span>
                  </span>
                </button>
              </div>
            </div>
            <div className="flex my-2">
              <p className="text-xs text-gray-900 font-extralight">
                The min deposit amount 0.1 USDT &nbsp;
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 24 24"
                fill="currentColor"
                class="size-4"
              >
                <path
                  fill-rule="evenodd"
                  d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </div>
          <div className="col">
            <div className='bg-white rounded-lg border'>
            <QRCode
              value={walletAddress}
              size={100}
              level={"H"}
              includeMargin={true}
            />
            </div>
          </div>
          </div>
          <div className='flex'>
            <span>Send only <span className='font-bold text-black'>{selectedCrypto.name}</span> to this address</span>
          </div>
      </>
    );
}

export default Crypto;
