import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, onValue, set, serverTimestamp, onDisconnect } from "firebase/database";
import { getPerformance } from "firebase/performance";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
    apiKey: "AIzaSyD9K-8aUrsiQBMNv6sQoXbEPnbbuKtV3MM",
    authDomain: "fwin-io.firebaseapp.com",
    projectId: "fwin-io",
    storageBucket: "fwin-io.appspot.com",
    messagingSenderId: "1021414195626",
    appId: "1:1021414195626:web:b0c16b571efee648e2abd3",
    measurementId: "G-TBTBMVNC4H",
    databaseURL: "https://fwin-io-default-rtdb.firebaseio.com/"
  };
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);
const perf = getPerformance(app);


// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export { analytics, database };


