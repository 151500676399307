// SliderComponent.js
import React from 'react';
import Slider from "react-slick";
import './proEmbla.css';
import evolution from '../../../Assets/proEvolution.svg';
import evoplay from '../../../Assets/proEvoplay.svg';
import pragmatic from '../../../Assets/proPragmatic.svg';
import ezugi from '../../../Assets/proEzugi.png';
import habanero from '../../../Assets/proHabanero.svg';
import playngo from '../../../Assets/proPlayngo.avif';
import playtech from '../../../Assets/proPlaytech.png';

const ProviderCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      }
    ]
  };

  return (
    <div >
      <Slider {...settings}>
        <div>
        <div style={{minHeight: '100%'}} className='border-2 border-indigo-600 bg-black p-4 m-1 rounded-md flex justify-center items-center h-12'>
          <img
            src={evolution}
            alt="Slide 1"
            style={{  height: '20px', width: '101px'}}
          />
        </div>
        </div>
        <div>
        <div style={{minHeight: '100%'}} className='border-2 border-indigo-600 bg-black p-4 m-1 rounded-md flex justify-center items-center h-12'>
          <img
            src={evoplay}
            alt="Slide 2"
            style={{ height: '20px', width: '60px' }}
          />
        </div>
        </div>
        <div>
        <div style={{minHeight: '100%'}} className='border-2 border-indigo-600 bg-black p-4 m-1 rounded-md flex justify-center items-center h-12'>
          <img
            src={pragmatic}
            alt="Slide 2"
            style={{ height: '30px', width: '65px' }}
          />
        </div>
        </div>
        <div>
        <div style={{minHeight: '100%'}} className='border-2 border-indigo-600 bg-black p-4 m-1 rounded-md flex justify-center items-center h-12'>
          <img
            src={ezugi}
            alt="Slide 2"
            style={{ height: '30px', width: '65px' }}
          />
        </div>
        </div>
        <div>
        <div style={{minHeight: '100%'}} className='border-2 border-indigo-600 bg-black p-4 m-1 rounded-md flex justify-center items-center h-12'>
          <img
            src={habanero}
            alt="Slide 2"
            style={{ height: '20px', width: '90px' }}
          />
        </div>
        </div>
        <div>
        <div style={{minHeight: '100%'}} className='border-2 border-indigo-600 bg-black p-4 m-1 rounded-md flex justify-center items-center h-12'>
          <img
            src={playngo}
            alt="Slide 2"
            style={{ height: '20px', width: '90px' }}
          />
        </div>
        </div>
        <div>
        <div style={{minHeight: '100%'}} className='border-2 border-indigo-600 bg-black p-4 m-1 rounded-md flex justify-center items-center h-12'>
          <img
            src={playtech}
            alt="Slide 2"
            style={{ height: '20px', width: '75px' }}
          />
        </div>
        </div>
      </Slider>
    </div>
  );
}

export default ProviderCarousel;
