import React, { useState, useEffect } from 'react';
import BuyCrypto from './Auth/BuyCrypto';


const Sandbox = () => {
  
  return (
    <>
    <BuyCrypto />
    </>
  );
};

  export default Sandbox;


 