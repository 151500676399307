import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../Components/Auth/AuthContext';  

const Deposits = () => {
    const { fwinBackendUrl } = useAuth();
    const [transactions, setTransactions] = useState([]);
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${fwinBackendUrl}/admin/crypto-deposits`);
                setTransactions(response.data);
            } catch (error) {
                console.error('Error fetching crypto deposits:', error);
            }
        };

        fetchData();
    }, []);

    const handleDepositRequest = async (transaction) => {
        try {
            const { email, amount, date, method, transactionId } = transaction;
            const requestBody = {
                email,
                amount,
                date,
                method,
                transactionId,
            };

            const response = await axios.post(`${fwinBackendUrl}/admin/deposit-request`, requestBody);
            console.log('Deposit updated successfully:', response.data);

            // Update the state with the new status
            setTransactions(prevTransactions => 
                prevTransactions.map(tx => 
                    tx.transactionId === transactionId ? { ...tx, status: 'CONFIRMED' } : tx
                )
            );

        } catch (error) {
            console.error('Error updating deposit:', error);
            alert('There was an error approving the deposit.');
        }
    };
    const handleRejectDeposit = async (transaction) => {
        try {
            const { email, amount, date, method, transactionId } = transaction;
            const requestBody = {
                email,
                amount,
                date,
                method,
                transactionId,
            };

            const response = await axios.post(`${fwinBackendUrl}/admin/reject-deposit`, requestBody);
            console.log('Deposit Rejected', response.data);

            // Update the state with the new status
            setTransactions(prevTransactions => 
                prevTransactions.map(tx => 
                    tx.transactionId === transactionId ? { ...tx, status: 'REJECTED' } : tx
                )
            );

        } catch (error) {
            console.error('Error updating deposit:', error);
            alert('There was an error approving the deposit.');
        }
    };

    return (
        <div className="overflow-x-auto max-h-[80vh]">
            <table className="min-w-full bg-white border border-gray-300">
                <thead>
                    <tr>
                        <th className="py-2 px-4 border-b">Date</th>
                        <th className="py-2 px-4 border-b">Amount</th>
                        <th className="py-2 px-4 border-b">Method</th>
                        <th className="py-2 px-4 border-b">Transaction Id</th>
                        <th className="py-2 px-4 border-b">Address</th>
                        <th className="py-2 px-4 border-b">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {transactions.map((transaction, index) => (
                        <tr className='min-h-min border-b' key={index}>
                            <td className="text-xs py-1 px-4 ">{new Date(transaction.date).toLocaleString()}</td> 
                            <td className="text-xs py-1 px-4 ">{transaction.amount}</td>
                            <td className="text-xs py-1 px-4  capitalize">{transaction.method}</td>
                            <td className="text-xs py-1 px-4 ">{transaction.transactionId}</td>
                            <td className="text-xs py-1 px-4 ">{transaction.address}</td>
                            <td className={`${transaction.status === "UNCONFIRMED" ? "flex" : ""} text-xs py-1 px-4`}>
                                {transaction.status === "UNCONFIRMED" ? (
                                    <>
                                        <button onClick={() => handleDepositRequest(transaction)} type="button" className="text-white text-xs bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                                            Approve
                                        </button>
                                        <button onClick={() => handleRejectDeposit(transaction)} type="button" className="text-white text-xs bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
                                            Reject
                                        </button>
                                    </>
                                ) : transaction.status === "CONFIRMED" ? (
                                    <span className='bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400'>
                                        Confirmed
                                    </span>
                                ) : transaction.status === "REJECTED" ? (
                                    <span className='bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400'>
                                        Rejected
                                    </span>
                                ) : null}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Deposits;
