import React, { useState, useEffect } from 'react';
import euro from '../../Assets/euro.png';
import { useAuth } from '../Auth/AuthContext';  
import axios from 'axios';

function CoinFlip() {
    const { fwinBackendUrl, currentUser, balance } = useAuth();
    const [data, setData] = useState([]);
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
      const fetchData = async () => {
          try {
              const response = await axios.get(`${fwinBackendUrl}/bets/cointoss/table`);
              const newEntries = response.data;
  
              // Combine new entries with existing data
              const updatedData = [...newEntries, ...data];
  
              // Sort combined data by createdAt in descending order
              const sortedData = updatedData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  
              // Keep only the latest 20 entries
              const limitedData = sortedData.slice(0, 20);
  
              // Update state with the latest 20 entries
              setData(limitedData);
          } catch (error) {
              console.error('Failed to fetch data:', error);
              // Optionally handle the error by resetting the data
          }
      };
  
      fetchData();
  }, [balance, fwinBackendUrl]);

    const handleCheckboxChange = () => {
        setIsChecked(prevIsChecked => !prevIsChecked);
    };

    const filteredData = isChecked ? data.filter(bet => bet.player === currentUser.email) : data;

    return (
        <>
            <section>
                <div className='space-y-5'>
                    <div className="flex items-center gap-2">
                        <div className="flex h-6 w-6 items-center justify-center rounded-md border">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-club h-4 w-4">
                                <path d="M17.28 9.05a5.5 5.5 0 1 0-10.56 0A5.5 5.5 0 1 0 12 17.66a5.5 5.5 0 1 0 5.28-8.6Z"></path>
                                <path d="M12 17.66L12 22"></path>
                            </svg>
                        </div>
                        <h3>Bets</h3>
                    </div>
                    <div className='flex items-center '>
                        <label className='themeSwitcherTwo shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-md p-1 bg-gray-200'>
                            <input
                                type='checkbox'
                                className='sr-only'
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                                disabled={!currentUser}
                            />
                            <span
                                className={`flex items-center space-x-[6px] rounded py-2 px-[18px] text-sm font-medium ${!isChecked ? 'text-primary bg-[#f4f7ff]' : 'text-body-color'
                                    }`}
                            >
                                All Bets
                            </span>
                            <span
                                className={`flex items-center space-x-[6px] rounded py-2 px-[18px] text-sm font-medium ${isChecked ? 'text-primary bg-[#f4f7ff]' : 'text-body-color'
                                    }`}
                            >
                                My Bets
                            </span>
                        </label>
                    </div>
                </div>
            </section>
            <section> 
                <div className='relative h-[400px] overflow-y-auto rounded-md border'>
                    <table className='w-full caption-bottom text-sm grid'>
                        <thead className='[&_tr]:border-b sticky top-0 z-10 grid bg-white'>
                            <tr className="text-[#71717a] font-bold border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted flex w-full">
                                <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground flex items-center xl:w-[12%] lg:w-[15%] md:w-[8%] sm:w-[9%] w-[16%]">Game</th>
                                <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground flex items-center xl:w-[19%] lg:w-[8.5%] md:w-[10%] sm:w-[13%] w-[34%]">Player</th>
                                <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground hidden sm:flex items-center xl:w-[10%] lg:w-[11%] md:w-[12.5%] sm:w-[20%] w-0">Bet</th>
                                <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground flex items-center xl:w-[16%] lg:w-[19.5%] md:w-[21%] sm:w-[26%] w-[20%]">Multiplier</th>
                                <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground flex items-center xl:w-[12%] lg:w-[15%] md:w-[15%] sm:w-[20%] w-[30%]">Payout</th>
                            </tr>
                        </thead>
                        <tbody className='[&_tr:last-child]:border-0 relative grid'>
                            {filteredData.map(bet => (
                                <tr key={bet._id} className="translate-y-0 border-b transition-colors hover:bg-muted/50 flex w-full">
                                    <td className="p-4 align-middle flex items-center xl:w-[12%] lg:w-[15%] md:w-[8%] sm:w-[9%] w-[16%]">
                                        <a className="flex items-center gap-2 text-poly-yellow" href="/en/game/coinflip">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
                                                <rect width="24" height="24" fill="#FFDB5B" rx="6"></rect>
                                                <path fill="#3D2F00" fillRule="evenodd" d="M17.8434 4.14129C17.1126 3.41053 16.1215 3 15.088 3H8.91195c-1.03344 0-2.02456.41053-2.75532 1.14129L4.14129 6.15663C3.41054 6.88739 3 7.87851 3 8.91195V15.088c0 1.0335.41053 2.0246 1.14129 2.7554l2.01534 2.0153C6.88739 20.5895 7.87851 21 8.91195 21H15.088c1.0335 0 2.0246-.4105 2.7554-1.1413l2.0153-2.0153C20.5895 17.1126 21 16.1215 21 15.088V8.91195c0-1.03344-.4105-2.02456-1.1413-2.75532l-2.0153-2.01534ZM6.49911 10.6277c-.76086.7608-.76086 1.9944 0 2.7553l4.13299 4.133c.7609.7608 1.9945.7608 2.7553 0l4.133-4.133c.7609-.7609.7609-1.9945 0-2.7553l-4.133-4.13303c-.7608-.76086-1.9944-.76086-2.7553 0L6.49911 10.6277Z" clipRule="evenodd"></path>
                                            </svg>
                                            <span className="hidden lg:block">Coinflip</span>
                                        </a>
                                    </td>
                                    <td className="p-4 align-middle flex items-center xl:w-[12%] lg:w-[15%] md:w-[8%] sm:w-[9%] w-[34%]">
                                        <a target="_blank" rel="noopener noreferrer">{bet.player.slice(0, 6)}...{bet.player.slice(-4)}</a>
                                    </td>
                                    <td className="p-4 align-middle hidden sm:flex items-center xl:w-[10%] lg:w-[11%] md:w-[12.5%] sm:w-[20%] w-0">
                                        <p className="flex items-center gap-2">{bet.bet}</p>
                                    </td>
                                    <td className="p-4 align-middle hidden md:flex items-center xl:w-[23%] lg:w-[21%] md:w-[23.5%] w-0">
                                        <div className="flex gap-2">
                                            <img alt="Coin" src={`path-to-coin-images/${bet.selectedCoin[1]}.png`} className="h-4 w-4 md:h-6 md:w-6" />
                                        </div>
                                    </td>
                                    <td className="p-4 align-middle flex items-center xl:w-[16%] lg:w-[19.5%] md:w-[21%] sm:w-[26%] w-[20%]">
                                        <div className="flex gap-2">
                                            <span className='text-black-500'>{bet.multiplier}x</span>
                                        </div>
                                    </td>
                                    <td className="p-4 align-middle flex items-center xl:w-[12%] lg:w-[15%] md:w-[15%] sm:w-[20%] w-[30%]">
                                        <span className={`text-${bet.status === 'win' ? 'green-500' : 'black-500'}`}>{bet.payout} &nbsp;</span> <img src={euro} width={20} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
}

export default CoinFlip;
