import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../Components/Auth/AuthContext';
import Chart from "react-apexcharts";

const Dashboard = () => {
  const { fwinBackendUrl, activeUsers } = useAuth();
  const [data, setData] = useState({
    userCount: 0,
    totalDeposit: 0,
    totalWithdrawal: 0
  });
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null);
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: "basic-bar"
      },
      xaxis: {
        categories: ['JAN','FEB' , 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP']
      }
    },
    series: [
      {
        name: "series-1",
        data: [1000, 2000, 3000, 4000, 5000, 6000, 7000, 9000]
      }
    ]
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${fwinBackendUrl}/admin/dashboard`);
        setData(response.data);
      } catch (error) {
        setError('Error fetching data');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
    <section>
                <div className='space-y-5'>
                    <div className="flex items-center gap-2">
                        <div className="flex h-6 w-6 items-center justify-center rounded-md border">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="lucide lucide-club h-4 w-4"
                            >
                                <path d="M17.28 9.05a5.5 5.5 0 1 0-10.56 0A5.5 5.5 0 1 0 12 17.66a5.5 5.5 0 1 0 5.28-8.6Z"></path>
                                <path d="M12 17.66L12 22"></path>
                            </svg>
                        </div>
                        <h3>Dashboard</h3>
                    </div>
                </div>
    </section>
    <section>
    <div className='py-4'>
          <div className='grid sm:grid-cols-1 md:grid-cols-4 gap-4'>
            <div className=''>
              <div className='max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                </svg>

                <a>
                  <h5 className='mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white'>{data.userCount}</h5>
                </a>
                <p className='mb-3 font-normal text-gray-500 dark:text-gray-400'>Users count</p>
                <a className='inline-flex font-medium items-center text-blue-600 hover:underline'>
                  See more
                  <svg className='w-3 h-3 ms-2.5 rtl:rotate-[270deg]' aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778" />
                  </svg>
                </a>
              </div>
            </div>
            <div className=''>
              <div className='max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                </svg>

                <a>
                  <h5 className='mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white'>€&nbsp;{data.totalDeposit}</h5>
                </a>
                <p className='mb-3 font-normal text-gray-500 dark:text-gray-400'>Total Deposits</p>
                <a className='inline-flex font-medium items-center text-blue-600 hover:underline'>
                  See more
                  <svg className='w-3 h-3 ms-2.5 rtl:rotate-[270deg]' aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778" />
                  </svg>
                </a>
              </div>
            </div>
            <div className=''>
              <div className='max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                <a>
                  <h5 className='mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white'>€&nbsp;{data.totalWithdrawal}</h5>
                </a>
                <p className='mb-3 font-normal text-gray-500 dark:text-gray-400'>Total Withdrawals</p>
                <a className='inline-flex font-medium items-center text-blue-600 hover:underline'>
                  See more
                  <svg className='w-3 h-3 ms-2.5 rtl:rotate-[270deg]' aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778" />
                  </svg>
                </a>
              </div>
            </div>
            <div className=''>
              <div className='max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M21 7.5V18M15 7.5V18M3 16.811V8.69c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061A1.125 1.125 0 0 1 3 16.811Z" />
                </svg>

                <a>
                  <h5 className='mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white'>{activeUsers}</h5>
                </a>
                <p className='mb-3 font-normal text-gray-500 dark:text-gray-400'>Active Players</p>
                <a className='inline-flex font-medium items-center text-blue-600 hover:underline'>
                  See more
                  <svg className='w-3 h-3 ms-2.5 rtl:rotate-[270deg]' aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
    </div>
    </section>
    <section>
      <div className='flex gap-4 justify-between max-h-96'>
        <div className='p-6  md:w-1/2  bg-white border border-gray-200 rounded-lg shadow'>
          <h3 className='text-left pb-2'>Monthly Deposits</h3>
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            width="100%"
            height="100%"
          />
        </div>
        <div className='p-6 flex-grow bg-white border border-gray-200 rounded-lg shadow'>
        <h3 className='text-left pb-2'>New Users</h3>
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="line"
            width="100%"
          />
        </div>
      </div>

    </section>
    <section>
    <div className='py-4'>
          <div className='grid sm:grid-cols-1 md:grid-cols-3 gap-4'>
            <div className=''>
              <div className='max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                </svg>

                <a>
                  <h5 className='mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white'>{data.userCount}</h5>
                </a>
                <p className='mb-3 font-normal text-gray-500 dark:text-gray-400'>Unverified Users</p>
              </div>
            </div>
            <div className=''>
              <div className='max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                </svg>

                <a>
                  <h5 className='mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white'>€&nbsp;{data.totalDeposit}</h5>
                </a>
                <p className='mb-3 font-normal text-gray-500 dark:text-gray-400'>Pending Deposits</p>
              </div>
            </div>
            <div className=''>
              <div className='max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                <a>
                  <h5 className='mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white'>€&nbsp;{data.totalWithdrawal}</h5>
                </a>
                <p className='mb-3 font-normal text-gray-500 dark:text-gray-400'>Pending Withdrawals</p>
              </div>
            </div>
          </div>
    </div>
    </section>
    </>
    
  );
};

export default Dashboard;
