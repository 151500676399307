import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handleClick = (page) => {
    onPageChange(page);
  };

  // Calculate the range of page numbers to display
  const maxPageNumbers = 3;
  let startPage = Math.max(1, currentPage - 1);
  let endPage = Math.min(totalPages, currentPage + 1);

  // Adjust the range if there are fewer than 3 pages to show
  if (endPage - startPage + 1 < maxPageNumbers) {
    if (startPage === 1) {
      endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);
    } else if (endPage === totalPages) {
      startPage = Math.max(1, endPage - maxPageNumbers + 1);
    }
  }

  return (
    <div className="flex justify-center mt-4">
      <button
        onClick={() => handleClick(currentPage - 1)}
        disabled={currentPage === 1}
        className="px-4 py-2 mx-1 bg-[#3230c4] text-white rounded hover:bg-blue-600 disabled:opacity-50"
      >
        Previous
      </button>
      {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map(page => (
        <button
          key={page}
          onClick={() => handleClick(page)}
          className={`px-4 py-2 mx-1 border rounded ${currentPage === page ? 'bg-[#3230c4] text-white' : 'bg-white text-[#3230c4] border-[#3230c4] hover:bg-blue-100'}`}
        >
          {page}
        </button>
      ))}
      <button
        onClick={() => handleClick(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="px-4 py-2 mx-1 bg-[#3230c4] text-white rounded hover:bg-blue-600 disabled:opacity-50"
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;
