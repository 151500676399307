import React, { useState } from 'react'; 
import Crypto from '../Dropdown/Crypto';
import { useAuth } from './AuthContext';
import euro from '../../Assets/euro.png'; 
import { auth } from '../../firebase';
import { signOut } from "firebase/auth";
import BuyCrypto from './BuyCrypto';

import visa from '../../Assets/payments/visa.webp';
import gpay from '../../Assets/payments/gpay.webp';
import mastercard from '../../Assets/payments/mastercard.webp';
import applepay from '../../Assets/payments/applepay.webp';

const ProfileSidebar = () => {
  const { currentUser, balance, email, uuid, fwinBackendUrl, fetchUserBalance} = useAuth();
  const [activeTab, setActiveTab] = useState('Profile');
  const [isDeposit, setIsDeposit] = useState(true);
  const [isPayment, setIsPayment] = useState(false);
  const [isCryptoPayment, setIsCryptoPayment] = useState(false);
  const [depositAmount, setDepositAmount] = useState(100);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [withdrawWalletAddress, setWithdrawWalletAddress] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleWithdrawRequest = async (e) => {
    e.preventDefault();
  
    // Form data to be sent in POST request
    const formData = {
      email: email,
      amount: withdrawAmount,
      walletAddress: withdrawWalletAddress
    };
  
    // Send POST request for withdrawal
    const response = await fetch(`${fwinBackendUrl}/admin/withdraw-request`, { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });
  
    // Check if response is OK
    if (response.ok) {
       const responseData = await response.json();
  
        // Fetch the updated user balance
        await fetchUserBalance(email);
  
        // Set success state and reset form fields
        setIsSubmitted(true);
        setWithdrawAmount('');
        setWithdrawWalletAddress('');
  
        console.log('Withdrawal request submitted successfully:', responseData.message);

    } else {
      // Handle HTTP errors based on response status and content
      const errorData = await response.json();
      console.error('HTTP Error:', errorData.message || 'Unknown error');
    }
  };
  const handleLogout = async () => {
    await signOut(auth);
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const handleAmountChange = (e) => {
    setDepositAmount(e.target.value);
  };
  const handleAmountClick = (value) => {
    setDepositAmount(value);
  };
  const handleMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };
  const handleDepositClick = () => {
    handleProcessPayment();
  };
  const handleSetPaymentFalse = () => {
    setIsPayment(false);
  };
  const handlePaymentRequest = () => {
  // logic for send payment request to the server
  };
  const handleProcessPayment = () => {
    setIsPayment(true);
    console.log(depositAmount)
    console.log(paymentMethod)
    console.log(isPayment)
  };
  const handleIsCryptoPayment = () => {
   setIsCryptoPayment(true);
  };
  const backbuttonCryptoPayment = () => {
    setIsCryptoPayment(false);
  };

  return (
    <div className="md:flex">
      <ul className="flex-column space-y space-y-4 text-sm font-medium text-gray-500 dark:text-gray-400 md:me-4 mb-4 md:mb-0">
        <li>
          <button
            onClick={() => handleTabClick("Profile")}
            className={`inline-flex items-center px-4 py-3 rounded-lg w-full ${
              activeTab === "Profile"
                ? "text-white bg-blue-700 dark:bg-blue-600"
                : "bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
            }`}
          >
            <svg
              className="w-4 h-4 me-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
            </svg>
            Profile
          </button>
        </li>
        <li>
          <button
            onClick={() => handleTabClick("Wallet")}
            className={`inline-flex items-center px-4 py-3 rounded-lg w-full ${
              activeTab === "Wallet"
                ? "text-white bg-blue-700 dark:bg-blue-600"
                : "bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-4 h-4 me-2"
            >
              <path d="M2.273 5.625A4.483 4.483 0 0 1 5.25 4.5h13.5c1.141 0 2.183.425 2.977 1.125A3 3 0 0 0 18.75 3H5.25a3 3 0 0 0-2.977 2.625ZM2.273 8.625A4.483 4.483 0 0 1 5.25 7.5h13.5c1.141 0 2.183.425 2.977 1.125A3 3 0 0 0 18.75 6H5.25a3 3 0 0 0-2.977 2.625ZM5.25 9a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h13.5a3 3 0 0 0 3-3v-6a3 3 0 0 0-3-3H15a.75.75 0 0 0-.75.75 2.25 2.25 0 0 1-4.5 0A.75.75 0 0 0 9 9H5.25Z" />
            </svg>
            Wallet
          </button>
        </li>
        <li>
          <button
            onClick={() => handleTabClick("Bets")}
            className={`inline-flex items-center px-4 py-3 rounded-lg w-full ${
              activeTab === "Bets"
                ? "text-white bg-blue-700 dark:bg-blue-600"
                : "bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-4 h-4 me-2"
            >
              <path
                fill-rule="evenodd"
                d="M1.5 6.375c0-1.036.84-1.875 1.875-1.875h17.25c1.035 0 1.875.84 1.875 1.875v3.026a.75.75 0 0 1-.375.65 2.249 2.249 0 0 0 0 3.898.75.75 0 0 1 .375.65v3.026c0 1.035-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 0 1 1.5 17.625v-3.026a.75.75 0 0 1 .374-.65 2.249 2.249 0 0 0 0-3.898.75.75 0 0 1-.374-.65V6.375Zm15-1.125a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-1.5 0V6a.75.75 0 0 1 .75-.75Zm.75 4.5a.75.75 0 0 0-1.5 0v.75a.75.75 0 0 0 1.5 0v-.75Zm-.75 3a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-1.5 0v-.75a.75.75 0 0 1 .75-.75Zm.75 4.5a.75.75 0 0 0-1.5 0V18a.75.75 0 0 0 1.5 0v-.75ZM6 12a.75.75 0 0 1 .75-.75H12a.75.75 0 0 1 0 1.5H6.75A.75.75 0 0 1 6 12Zm.75 2.25a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Z"
                clip-rule="evenodd"
              />
            </svg>
            Bets
          </button>
        </li>
        <li>
          <button
            onClick={() => handleTabClick("Promos")}
            className={`inline-flex items-center px-4 py-3 rounded-lg w-full ${
              activeTab === "Promos"
                ? "text-white bg-blue-700 dark:bg-blue-600"
                : "bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-4 h-4 me-2"
            >
              <path
                fill-rule="evenodd"
                d="M11.99 2.243a4.49 4.49 0 0 0-3.398 1.55 4.49 4.49 0 0 0-3.497 1.306 4.491 4.491 0 0 0-1.307 3.498 4.491 4.491 0 0 0-1.548 3.397c0 1.357.6 2.573 1.548 3.397a4.491 4.491 0 0 0 1.307 3.498 4.49 4.49 0 0 0 3.498 1.307 4.49 4.49 0 0 0 3.397 1.549 4.49 4.49 0 0 0 3.397-1.549 4.49 4.49 0 0 0 3.497-1.307 4.491 4.491 0 0 0 1.306-3.497 4.491 4.491 0 0 0 1.55-3.398c0-1.357-.601-2.573-1.549-3.397a4.491 4.491 0 0 0-1.307-3.498 4.49 4.49 0 0 0-3.498-1.307 4.49 4.49 0 0 0-3.396-1.549Zm3.53 7.28a.75.75 0 0 0-1.06-1.06l-6 6a.75.75 0 1 0 1.06 1.06l6-6Zm-5.78-.905a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25Zm4.5 4.5a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25Z"
                clip-rule="evenodd"
              />
            </svg>
            Promos
          </button>
        </li>
      </ul>
      <div className="bg-gray-50 text-medium text-gray-500 dark:text-gray-400 dark:bg-gray-800 rounded-lg w-full">
        {activeTab === "Profile" && (
          <div className="max-w-md mx-auto py-8 px-6 my-6 md:mx-0 md:my-0 bg-white border rounded-lg">
            <h2 className="text-2xl font-bold mb-4 text-left text-gray-500 font-light">
              <span className="font-normal text-black">User ID: {uuid}</span>
            </h2>
            <p className="text-lg font-bold mb-4 text-left text-gray-500 font-light">
              Email:{" "}
              <span className="text-xs text-black font-light">{email}</span>
            </p>
            <p className="text-lg font-bold mb-4 text-left text-gray-500 font-light">
              My balance:{" "}
              <span className="text-lg text-black font-light">{balance}</span>
            </p>
            <div className="flex justify-start">
              <p className="text-lg font-bold mb-4 text-left text-gray-500 font-light">
                Currency: &nbsp;
              </p>{" "}
              <span className="text-lg text-black font-light">EUR &nbsp;</span>
              <img className="h-[22px]" src={euro} />
            </div>
            <button
              className="bg-gray-200 w-full rounded-md p-2 text-black"
              onClick={handleLogout}
            >
              logout
            </button>
          </div>
        )}
        {activeTab === "Wallet" && (
          <div className="max-w-md mx-auto py-8 px-6 my-6 md:mx-0 md:my-0 bg-white border rounded-lg">
            <div className="flex mb-8">
              <div className="flex-col border bg-blue-700 w-full rounded-lg px-6 py-4">
                <p className="text-white font-bold text-2xl text-left">
                  € {balance}
                </p>
                <p className="text-white font-thin text-sm text-left">
                  Total Balance
                </p>
              </div>
            </div>
            <div className="flex space-x-4 border-b border-slate-200">
              <div
                className={`flex-1 flex items-center justify-center cursor-pointer ${
                  isDeposit ? "text-blue-500 border-b-2 border-blue-500" : ""
                }`}
                onClick={() => setIsDeposit(true)}
              >
                <span className="font-bold">Deposit</span>
              </div>
              <div
                className={`flex-1 flex items-center justify-center cursor-pointer ${
                  !isDeposit ? "text-blue-500 border-b-2 border-blue-500" : ""
                }`}
                onClick={() => setIsDeposit(false)}
              >
                <span className="font-bold">Widthraw</span>
              </div>
            </div>
            <div className="form-container">
              {isDeposit ? (
                <div>
                  {!isPayment ? (
                    <div>
                      {!isCryptoPayment ? (
                        
                        <div className="mt-8">
                        <div>
                          <Crypto />
                        </div>
                        <div>
                          <p className="text-black my-6">Or</p>
                        </div>
                        <div onClick={handleIsCryptoPayment} className="bg-black rounded-lg py-2 cursor-pointer transition-transform transform hover:scale-95 duration-200 ease-in-out">
                          <p className="text-white pb-2">Buy Crypto</p>
                          <div className="flex gap-2 bg-black justify-center">
                            <img src={visa} className="h-[24px]" />
                            <img src={gpay} className="h-[24px]" />
                            <img src={mastercard} className="h-[24px]" />
                            <img src={applepay} className="h-[24px]" />
                          </div>
                        </div>
                      </div>
                      ) : (
                        // This will show when isCryptoPayment is true
                        <div>
                          <BuyCrypto backButton={backbuttonCryptoPayment} />
                        </div>
                      )}
                      
                    </div>
                  ) : (
                    <div className="h-full w-full rounded-lg p-6 my-2 border-2 border-dashed">
                      <div className="flex justify-center">
                        <img
                          className=""
                          src="https://miro.medium.com/v2/resize:fit:1400/format:webp/1*94MvdhxeCQHoD7A4K1vlWg.png"
                        ></img>
                      </div>
                      <div className="py-2">
                        <p className="text-sm font-light">
                          Please send your deposit payment only to the current
                          UPI Address.
                        </p>
                      </div>
                      <div className="py-8">
                        <div class="grid grid-cols-8 gap-2 w-full max-w-[23rem]">
                          <label for="npm-install" class="sr-only">
                            Label
                          </label>
                          <input
                            id="npm-install"
                            type="text"
                            class="col-span-6 bg-gray-50 border border-gray-300 font-mono text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            value="7761689470@indianbank"
                            disabled
                            readonly
                          />
                          <button
                            data-copy-to-clipboard-target="npm-install"
                            class="col-span-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 items-center inline-flex justify-center"
                          >
                            <span id="default-message">Copy</span>
                            <span
                              id="success-message"
                              class="hidden inline-flex items-center"
                            >
                              <svg
                                class="w-3 h-3 text-white me-1.5"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 16 12"
                              >
                                <path
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M1 5.917 5.724 10.5 15 1.5"
                                />
                              </svg>
                              Copied!
                            </span>
                          </button>
                        </div>
                      </div>
                      <div>
                        <div class="relative">
                          <input
                            type="text"
                            id="floating_outlined"
                            class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                          />
                          <label
                            for="floating_outlined"
                            class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Enter UTR
                          </label>
                        </div>
                      </div>
                      <div className="pb-4 flex gap-4">
                        <button
                          onClick={handleSetPaymentFalse}
                          class="mt-6 items-center w-full justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-[#3230C4] text-white text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2 block mx-auto sm:hidden"
                          type="button"
                        >
                          Back
                        </button>
                        <button
                          onClick={handlePaymentRequest}
                          class="mt-6 items-center w-full justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-[#3230C4] text-white text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2 block mx-auto sm:hidden"
                          type="button"
                        >
                          Confirm
                        </button>
                      </div>
                      <div className="flex text-xs justify-center font-thin">
                        powered by&nbsp;{" "}
                        <img
                          className="w-[68px]"
                          src="https://fairpayy.com/static/media/Fairpayy.aac7cc1aee7f4de1e66a.png"
                        ></img>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <div className="flex py-8">
                    <p className="font-bold text-left text-sm">Withdraw</p>
                    <p className=" px-4 text-gray-400 text-sm">
                      Min 50 - Max 100,000
                    </p>
                  </div>
                  <div>
                    <form
                      className="max-w-sm mx-auto"
                      onSubmit={handleWithdrawRequest}
                    >
                      <label
                        htmlFor="withdrawAmount"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Enter Amount
                      </label>
                      <div className="relative">
                        <input
                          type="text"
                          id="withdrawAmount"
                          value={withdrawAmount}
                          onChange={(e) => setWithdrawAmount(e.target.value)}
                          className="bg-gray-50 text-right border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 px-4"
                          placeholder="300"
                        />
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none font-bold">
                          EUR
                        </div>
                      </div>

                      <div className="flex justify-center mt-4">
                        <span className="text-black">USDT-TRC20 &nbsp;</span>
                        <img
                          src="https://cqkxqqwxpqbjtoocllkm.supabase.co/storage/v1/object/public/crypto%20icons/usdt.svg"
                          width={22}
                          alt="USDT-TRC20"
                        />
                      </div>

                      <input
                        id="withdrawWalletAddress"
                        type="text"
                        value={withdrawWalletAddress}
                        onChange={(e) =>
                          setWithdrawWalletAddress(e.target.value)
                        }
                        className="mt-2 col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Enter USDT TRC-20 wallet address"
                      />
                      <p className="text-xs mt-2 text-black">
                        Enter USDT Trc-20 wallet address
                      </p>

                      <button
                        className="mt-6 items-center w-full justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-[#3230C4] text-white text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2 block mx-auto"
                        type="submit"
                      >
                        Withdraw Funds
                      </button>

                      {isSubmitted && (
                        <div className="text-center text-green-500 mt-4">
                          Withdraw request sent successfully!
                        </div>
                      )}

                      <div className="flex justify-center pt-4">
                        <svg
                          className=""
                          width="122"
                          height="32"
                          viewBox="0 0 122 32"
                        >
                          {/* SVG content here */}
                        </svg>
                      </div>
                    </form>
                  </div>
                  <div className="withdraw-list"></div>
                </div>
              )}
            </div>
          </div>
        )}
        {activeTab === "Bets" && (
          <div>
            <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-2">
              Bet History
            </h3>
            <p className="mb-2"></p>
          </div>
        )}
        {activeTab === "Promos" && (
          <div>
            <h3 className="text-lg font-bold text-gray-900 dark:text-white mb-2">
              FWIN Promos
            </h3>
            <p className="mb-2"></p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileSidebar;
