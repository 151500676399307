import React, { createContext, useState, useEffect, useContext } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, database } from '../../firebase';
import { ref, onValue, set, serverTimestamp, onDisconnect, runTransaction, get, getDatabase } from "firebase/database";
import { v4 as uuidv4 } from 'uuid';

const AuthContext = createContext(); 

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [balance, setBalance] = useState(null);
  const [email, setEmail] = useState("");
  const [uuid, setUuid] = useState("");
  const [country, setCountry] = useState("EUR");
  const [fwinBackendUrl, setFwinBackendUrl] = useState("https://fwin-backend.onrender.com");
  const [activeUsers, setActiveUsers] = useState(0);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUuid(user.uid.substring(0, 7));
        setEmail(user.email);
        setCurrentUser(user);
        fetchUserBalance(user.email);
      } else {
        setUuid("");
        setEmail("");
        setCurrentUser(null);
        setBalance(0);
      }
    });


    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const handleUserPresence = onAuthStateChanged(auth, (user) => {
      if (!user) {
        // If no user is logged in, return early and do nothing
        return;
      }
  
      const db = getDatabase();
      const activeUsersRef = ref(db, 'activeUsers');
      const usersRef = ref(db, 'users');
  
      // Function to update active users count
      const updateActiveUsersCount = async () => {
        try {
          const snapshot = await get(usersRef);
          let onlineCount = 0;
          snapshot.forEach((childSnapshot) => {
            const userStatus = childSnapshot.val();
            if (userStatus.online) {
              onlineCount += 1;
            }
          });
          console.log('Setting active users count to:', onlineCount);
          await set(activeUsersRef, onlineCount);
          setActiveUsers(onlineCount); // Update state here
        } catch (error) {
          console.error('Error updating active users count:', error);
        }
      };
  
      // Listen for changes in the users node
      const usersRefListener = onValue(usersRef, () => {
        updateActiveUsersCount();
      });
  
      // Generate a unique session ID for each visitor
      let sessionId = localStorage.getItem('sessionId');
      if (!sessionId) {
        sessionId = uuidv4(); // Generate a new UUID if none exists
        localStorage.setItem('sessionId', sessionId);
      }
  
      const userStatusRef = ref(db, `users/${sessionId}`);
  
      // Mark the visitor as online and update their status
      set(userStatusRef, {
        online: true,
        lastChanged: serverTimestamp(),
        isGuest: !auth.currentUser, // Mark as guest if no user is logged in
      })
        .then(() => {
          // Update active users count on initial connection
          updateActiveUsersCount();
        })
        .catch((error) => {
          console.error('Error setting user status:', error);
        });
  
      // Handle disconnection
      onDisconnect(userStatusRef)
        .set({
          online: false,
          lastChanged: serverTimestamp(),
          isGuest: !auth.currentUser,
        })
        .then(() => {
          // Update active users count on disconnection
          updateActiveUsersCount();
        })
        .catch((error) => {
          console.error('Error handling disconnection:', error);
        });
  
      return () => {
        usersRefListener(); // Clean up the listener on component unmount
      };
    });
  
    // Cleanup the auth listener on component unmount
    return () => {
      handleUserPresence();
    };
  }, []);

  


  const fetchUserBalance = async (userEmail) => {
    const payload = { email: userEmail };

    try {
      const response = await fetch(`${fwinBackendUrl}/api/user-balance`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.ok) {
        setBalance(data);
        sync(userEmail);
      } else {
        console.error('Failed to fetch balance:', data.msg);
      }
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  };

  const sync = async (email) => {
    try {
      await fetch(`${fwinBackendUrl}/api/sync`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      console.log('Synchronized');
    } catch (err) {
      console.error('Error during balance synchronization:', err);
    }
  };

  const updateBalance = () => {
    if (currentUser) {
      fetchUserBalance(currentUser.email);
    }
  };

  return (
    <AuthContext.Provider value={{ currentUser, balance, email, uuid, country, fwinBackendUrl, activeUsers,  fetchUserBalance, updateBalance }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext };
